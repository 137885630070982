@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind variants; */

.swiper-pagination-bullet-active {
  background-color: #000 !important;
  width: 13px;
  height: 13px;
}

.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
}
